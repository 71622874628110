export const useUrlWithRedirect = (url: string) => {
  const route = useRoute();
  return route.query.redirect
    ? url + '?redirect=' + route.query.redirect
    : url;
};

export const useReplaceUrlSearchParamsWithoutRouter = (params: Record<string, any>, replace: boolean = false): void => {
  const url = new URL(window.location.toString());
  const searchParams = replace ? new URLSearchParams() : url.searchParams;
  Object.entries(params).forEach(([key, value]) => {
    if ([null, undefined, ''].includes(value) || (Array.isArray(value) && !value.length)) {
      searchParams.delete(key);
    } else {
      searchParams.set(key, Array.isArray(value) ? value.join(',') : value);
    }
  });
  url.search = searchParams.toString();
  window.history.replaceState(null, '', url);
};
